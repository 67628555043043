import React from 'react'
import styled from 'styled-components'
import Modal from 'react-modal'
import Button from '../../form/buttons'
import IconClose from '../../../images/ic_close.svg'
import PersonImg from '../../../images/person.png'
import { listDataPersonalInfo } from '../../../utils/api'

const yearRange = () => {
  const currentYear = new Date().getFullYear()
  const range = (start, stop, step) =>
    Array.from(
      { length: (stop - start) / step + 1 },
      (_, i) => start + i * step
    )
  return range(currentYear, currentYear - 120, -1)
}

const monthRange = {
  en: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ],
  th: [
    'มกราคม',
    'กุมภาพันธ์',
    'มีนาคม',
    'เมษายน',
    'พฤษภาคม',
    'มิถุนายน',
    'กรกฎาคม',
    'สิงหาคม',
    'กันยายน',
    'ตุลาคม',
    'พฤศจิกายน',
    'ธันวาคม',
  ],
}

const Header = styled.p`
  font-size: 24px;
  margin-bottom: 36px;
`

const Line = styled.hr`
  border: 0;
  height: 1px;
  background: #333333;
  margin: 20px 0;
`

function MemberDetail(props) {
  console.log('props:', props)

  const { list, lang } = props
  const {
    firstname,
    lastname,
    student_id,
    curriculum,
    major,
    class_of,
    phone_number,
    line_id,
    work_information = [],
    current_address,
    email,
    facebook,
    curriculum_other,
    major_other,
    profile_img_url,
    status,
    work_publish,
    personal_publish,
  } = props.detail.user_info

  const renderSelectList = (array, key) => {
    if (
      array.filter(item => {
        return item.id === +key
      }).length > 0
    ) {
      return array.filter(item => {
        return item.id === +key
      })[0].value
    }
    return ''
  }

  const renderDate = item => {
    if (item.until_now) {
      return `${yearRange()[item.from_year] || ''} ${monthRange[lang][
        item.from_month
      ] || ''} - ปัจจุบัน`
    } else {
      return `${yearRange()[item.from_year] || ''} ${monthRange[lang][
        item.from_month
      ] || ''} - ${yearRange()[item.to_year] || ''} ${monthRange[lang][
        item.to_month
      ] || ''}`
    }
  }

  return (
    <div>
      <Modal
        ariaHideApp={false}
        isOpen={props.openModal}
        onRequestClose={props.closeModal}
        className="information__modal"
        overlayClassName="information__modal__overlay"
        style={{
          content: {
            width: '100%',
            maxHeight: '90vh',
          },
        }}
      >
        <img
          src={IconClose}
          alt=""
          className="information__modal__close"
          onClick={props.closeModal}
        />
        <Header>ข้อมูลสมาชิก</Header>
        <div style={{ width: '100%', maxHeight: '90vh', overflow: 'auto' }}>
          <div>
            <img
              src={profile_img_url ? profile_img_url : PersonImg}
              alt=""
              className="information__user-img"
            />
          </div>
          <div>
            <p className="information__label">
              {props.locale['NAME_AND_FAMILY_NAME']}
            </p>
            <input
              type="text"
              value={`${firstname} ${lastname}`}
              className="information__input"
              disabled
            />
          </div>
          <div>
            <p className="information__label">{props.locale['STUDENT_ID']}</p>
            <input
              type="text"
              value={student_id}
              className="information__input"
              disabled
            />
          </div>
          <div>
            <p className="information__label">{props.locale['CURRICULUM']}</p>
            <input
              type="text"
              value={
                list.curriculum[lang][curriculum] && curriculum !== '8'
                  ? renderSelectList(list.curriculum[lang], curriculum)
                  : curriculum_other
              }
              className="information__input"
              disabled
            />
          </div>
          <div>
            <p className="information__label">{props.locale['MAJOR']}</p>
            <input
              type="text"
              value={
                list.major[lang][major] && major !== '35'
                  ? renderSelectList(list.major[lang], major)
                  : major_other
              }
              className="information__input"
              disabled
            />
          </div>
          <div>
            <p className="information__label">{props.locale['CLASS_OF']}</p>
            <input
              type="text"
              value={class_of}
              className="information__input"
              disabled
            />
          </div>
          <div>
            <p className="information__label">{props.locale['LINE_ID']}</p>
            <input
              type="text"
              value={line_id}
              className="information__input"
              disabled
            />
          </div>

          {work_publish && (
            <React.Fragment>
              <Line />
              <div>
                <p className="information__label">สถานะ</p>
                <input
                  type="text"
                  value={
                    status === 'working'
                      ? 'ทำงานอยู่'
                      : status === 'retired'
                      ? 'ไม่ทำงานแล้ว'
                      : ''
                  }
                  className="information__input"
                  disabled
                />
              </div>
              {work_information.map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    <div>
                      <p className="information__label">
                        {props.locale['FROM']} - {props.locale['TO']}
                      </p>
                      <input
                        type="text"
                        value={renderDate(item)}
                        className="information__input"
                        disabled
                      />
                    </div>
                    <div>
                      <p className="information__label">
                        {props.locale['POSITION']}
                      </p>
                      <input
                        type="text"
                        value={item.position}
                        className="information__input"
                        disabled
                      />
                    </div>
                    <div>
                      <p className="information__label">
                        {props.locale['COMPANY_DEPARTMENT']}
                      </p>
                      <input
                        type="text"
                        value={item.company}
                        className="information__input"
                        disabled
                      />
                    </div>
                    <div>
                      <p className="information__label">
                        {props.locale['BUSINESS_TYPE']}
                      </p>
                      <input
                        type="text"
                        value={
                          list.business_type[lang][item.business_type] &&
                          item.business_type !== '26'
                            ? renderSelectList(
                                list.business_type[lang],
                                item.business_type
                              )
                            : item.business_type_other
                        }
                        className="information__input"
                        disabled
                      />
                    </div>
                    <div>
                      <p className="information__label">
                        {props.locale['SKILL_EXPERIENCE']}
                      </p>
                      <input
                        type="text"
                        value={item.skill}
                        className="information__input"
                        disabled
                      />
                    </div>
                  </React.Fragment>
                )
              })}
            </React.Fragment>
          )}

          {personal_publish && (
            <React.Fragment>
              <Line />
              <div>
                <p className="information__label">
                  {props.locale['CURRENT_ADDRESS']}
                </p>
                <input
                  type="text"
                  value={current_address}
                  className="information__input"
                  disabled
                />
              </div>
              <div>
                <p className="information__label">{props.locale['E_MAIL']}</p>
                <input
                  type="text"
                  value={email}
                  className="information__input"
                  disabled
                />
              </div>
              <div>
            <p className="information__label">{props.locale['PHONE_NUMBER']}</p>
            <input
              type="text"
              value={phone_number}
              className="information__input"
              disabled
            />
          </div>
              <div>
                <p className="information__label">{props.locale['FACEBOOK']}</p>
                <input
                  type="text"
                  value={facebook}
                  className="information__input"
                  disabled
                />
              </div>
            </React.Fragment>
          )}
        </div>
      </Modal>
    </div>
  )
}

export default MemberDetail
