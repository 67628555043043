import React, { useState } from 'react'
import s from 'styled-components'
import swal from 'sweetalert'

import { Modal, ModalContent, ModalHeader } from './index'
import Loading from '../loading'
import Button from '../form/buttons'
import ModalHandler from '../../utils/modal'
import DragNDropFile from './drag-drop-file'
import XLSX from 'xlsx'
const Body = s.div`
  width: 100%;
  padding: 20px;
  text-align: justify;
`

const Table = s.table`
  width: 100%;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 40px;

  th, tr, td {
    border: 1px solid;
  }
`

const ALink = s.a`
   color: blue;
`

const style1 = { textAlign: 'right', borderTop: '1px solid #E8E8E8' }
const handleClose = (id, onClose) => {
  return () => {
    ModalHandler.closeModal(id)
    if (onClose) {
      onClose()
    }
  }
}

const ImportDetail = ({ lang, locale }) => {
  if (lang === 'EN') {
    return (
      <div>
        <p>{locale.IMPORT_INVITATION_DETAIL}</p>
        <p>
          {locale.IMPORT_INVITATION_DETAIL_NEXT} &nbsp;
          <ALink href={`${process.env.XLSX_TEMPLATE}`} target="_blank">
            {locale.XLSX_TEMPLATE}
          </ALink>
          &nbsp;
          {locale.AND} &nbsp;
          <ALink href={`${process.env.CSV_TEMPLATE}`} target="_blank">
            {locale.CSV_TEMPLATE}
          </ALink>
          {locale.FULL_STOP}
        </p>
      </div>
    )
  } else {
    return (
      <div>
        <p>{locale.IMPORT_INVITATION_DETAIL}</p>
        <p>
          {locale.EXAMPLE_TEMPLATE} &nbsp;
          <ALink href={`${process.env.XLSX_TEMPLATE_INVITE}`} target="_blank">
            {locale.XLSX}
          </ALink>
          <br />
          {locale.EXAMPLE_TEMPLATE} &nbsp;
          <ALink href={`${process.env.CSV_TEMPLATE_INVITE}`} target="_blank">
            {locale.CSV}
          </ALink>
        </p>
        <p>{locale.IMPORT_INVITATION_DETAIL_NEXT}</p>
      </div>
    )
  }
}

const DataTemplate = () => (
  <div>
    <Table>
      <tbody>
        <tr>
          <th>NAME</th>
          <th>EMAIL</th>
        </tr>
        <tr>
          <td>John Doe</td>
          <td>John@invitree.me</td>
        </tr>
        <tr>
          <td>คุณอาทิตย์</td>
          <td>arthid@gmail.com</td>
        </tr>
      </tbody>
    </Table>
  </div>
)

const DragNDropShowFileName = ({ fileName }) => {
  if (!fileName || fileName.length === 0) {
    return <p style={{ color: '#E8E8E8', fontSize: 36 }}>Drag file here</p>
  } else {
    return (
      <p style={{ color: '#E8E8E8', fontSize: 24 }}>
        {fileName}
        <br />
        {'(Drag file here to replace)'}
      </p>
    )
  }
}

export const ModalClose = ({
  id,
  onSubmit,
  onClose,
  textSend = 'Send',
  textClose = 'Close',
}) => (
  <div className="modal-header" style={style1}>
    <Button.Button5 onClick={onSubmit} style={{ marginRight: 10 }}>
      {textSend}
    </Button.Button5>
    <Button.Button2 onClick={handleClose(id, onClose)}>
      {textClose}
    </Button.Button2>
  </div>
)

const styleBoxLoad = { position: 'relative', height: '80px' }

function handle(props) {
  const [isLoading, setIsLoading] = useState(false)
  const [fileName, setFileName] = useState()
  const [file, setFile] = useState()
  const [refInput, setRefInput] = useState('')

  const onSubmit = () => {
    if (!file || file.length === 0) {
      swal(
        props.locale.ERROR,
        props.locale.WARNING_INVALID_INPUT + '!',
        'error'
      )
    } else {
      setIsLoading(true)
      props.onSubmit({ file }, res => {
        const { code, text } = res
        if (code === 'FAIL') {
          swal(props.locale.ERROR, (text && text.error) || '', 'error')
        } else {
          ModalHandler.closeModal('modalImportMyClub')
        }
        setIsLoading(false)
        onReset()
      })
    }
  }
  const onReset = () => {
    setFileName()
    setFile()
    setRefInput('')
  }
  const processDataCSV = data => {
    let csvData = []
    let lbreak = data.split('\n')
    lbreak.forEach(res => {
      csvData.push(res.split(','))
    })
    return csvData
  }
  const fileToBufferParser = async files => {
    const blobs = Array.from(files)

    const newBuffers = await Promise.all(
      blobs.map(async buf => {
        try {
          const buffer = await getBufferFromFile(buf)

          return new Uint8Array(buffer)
        } catch (error) {
          console.log('fileToBufferParser -> error', error)
        }
      })
    )

    const allBuffers = concatArrayBuffers([...newBuffers])

    return allBuffers
  }
  const getBufferFromFile = async (file, readAs = 'array') => {
    return new Promise((resolve, reject) => {
      let reader = new FileReader()

      reader.onload = event => {
        let data = event.target.result

        if (!data) return reject(console.log('no data'))
        return resolve(data)
      }

      readAs === 'array'
        ? reader.readAsArrayBuffer(file)
        : reader.readAsBinaryString(file)
    })
  }

  const concatArrayBuffers = buffers => {
    let offset = 0
    let bytes = 0

    let newBuffers = buffers.map((buffer, total) => {
      bytes += buffer.byteLength
      return buffer
    })

    let buffer = new ArrayBuffer(bytes)
    let store = new Uint8Array(buffer)

    newBuffers.forEach(buffer => {
      store.set(
        new Uint8Array(buffer.buffer || buffer, buffer.byteOffset),
        offset
      )
      offset += buffer.byteLength
    })

    return buffer
  }
  const readFileImport = (type, dragFile, callback) => {
    const reader = new FileReader()
    const rABS = !!reader.readAsBinaryString
    reader.addEventListener('load', e => {
      if (type === 'csv') {
        callback(processDataCSV(reader.result))
        return
      }
    })
    reader.readAsText(dragFile)
  }

  const checkSupportFile = async file => {
    if (file && file.length > 0) {
      let name = file[0].name
      if (name.lastIndexOf('csv') > 0) {
        readFileImport('csv', file[0], arr => {
          const newFile = (arr && arr.filter((_, idx) => idx > 1)) || []
          if (newFile.length > 50) {
            swal(
              props.locale.ERROR,
              props.locale.ERROR_UPLOAD_CSV_XLSX,
              'error'
            )
            return
          }
          setFileName(name)
          setFile(newFile)
        })
      } else if (name.lastIndexOf('xlsx') > 0) {
        const buffersArray = await fileToBufferParser(file)
        let workbook = XLSX.read(buffersArray, {
          type: 'array',
        })

        const sheetNames = workbook.SheetNames
        const worksheet = workbook.Sheets[sheetNames[0]]
        const convertXlsx = XLSX.utils.sheet_to_json(worksheet)
        if (convertXlsx.length > 50) {
          swal(props.locale.ERROR, props.locale.ERROR_UPLOAD_CSV_XLSX, 'error')
          return
        }
        let newArrayFile = []
        for (let i = 0; i < convertXlsx.length; i++) {
          let subArrayFile = []
          subArrayFile.push(convertXlsx[i].NAME)
          subArrayFile.push(convertXlsx[i].EMAIL)
          subArrayFile.push(convertXlsx[i].MESSAGE)
          subArrayFile.push(convertXlsx[i].LANGUAGE)
          newArrayFile.push(subArrayFile)
        }
        setFileName(name)
        setFile(newArrayFile)
      } else {
        swal(props.locale.ERROR, props.locale.ERROR_UPLOAD_CSV_XLSX, 'error')
      }
    }
  }

  const handleDrop = dragFiles => checkSupportFile(dragFiles)
  const handleFileSeleted = event => checkSupportFile(event.target.files)
  const handleRefInput = ref => {
    if (ref && refInput !== ref) {
      setRefInput(ref)
    }
  }

  return {
    isLoading,
    onSubmit,
    onReset,
    fileName,
    handleDrop,
    handleFileSeleted,
    refInput,
    handleRefInput,
  }
}

function ImportMyClub(props) {
  const {
    isLoading,
    onSubmit,
    onReset,
    fileName,
    handleDrop,
    handleFileSeleted,
    refInput,
    handleRefInput,
  } = handle(props)

  return (
    <Modal id="modalImportMyClub">
      <ModalContent maxWidth={460}>
        <ModalHeader
          id="modalImportMyClub"
          title={props.locale.IMPORT_INVITATION}
          onClose={onReset}
        />

        <Body>
          {isLoading && (
            <div style={styleBoxLoad}>
              <Loading />
            </div>
          )}
          <ImportDetail lang={props.lang} locale={props.locale} />
          <DataTemplate />
          <DragNDropFile handleDrop={handleDrop}>
            <div
              style={{
                height: 200,
                width: '100%',
                marginLeft: 'auto',
                marginRight: 'auto',
                border: '2px dashed #707070',
                borderRadius: '6px',
                backgroundColor: 'rgba(255,255,255,.8)',
              }}
            >
              <div
                style={{
                  position: 'absolute',
                  bottom: '30%',
                  right: 0,
                  left: 0,
                  textAlign: 'center',
                }}
              >
                <div>
                  <DragNDropShowFileName fileName={fileName} />
                  <input
                    type="file"
                    style={{ display: 'none' }}
                    onChange={handleFileSeleted}
                    ref={refInput => handleRefInput(refInput)}
                  />
                  <Button.Button5 onClick={() => refInput.click()}>
                    Browse
                  </Button.Button5>
                </div>
              </div>
            </div>
          </DragNDropFile>
        </Body>

        <ModalClose
          id="modalImportMyClub"
          onSubmit={onSubmit}
          onClose={onReset}
          textSend={props.locale.SEND}
          textClose={props.locale.CLOSE}
        />
      </ModalContent>
    </Modal>
  )
}

export default ImportMyClub

ImportMyClub.defaultProps = {
  onSubmit: null,
}
