import React, { useState } from 'react'
import s from 'styled-components'
import swal from 'sweetalert'
import Loading from '../loading'
import { Modal, ModalContent, ModalHeader } from './index'
import Button from '../form/buttons'
import ModalHandler from '../../utils/modal'

const Body = s.div`text-align:center;padding: 10px;`
const Table = s.table`
  width:100%;
  & > tbody > tr > td {
    padding: 10px;
    text-align: left;
  }
 `

const TdInput = s.td`
  // color:#C14C40;
  & > input , & > textarea , & > select{
    border: 1px solid #887979;
    border-radius: 6px;
    padding: 10px 10px;
    width: 100%;
  }
  , & > select{
    height: 45px;
    background: #ffffff;
  }
`

// const ReceiversSection = s.div`
//   margin-top:10px;
//   & > div {
//     padding: 5px 0;
//   }
//   & span {
//     background-color: #C14C40;
//     padding: 2px 6px 3px;
//     color: #ffffff;
//     margin-left: 5px;
//     cursor: pointer;
//   }
// `

const style1 = { textAlign: 'right', borderTop: '1px solid #E8E8E8' }
const handleClose = (id, onClose) => {
  return () => {
    ModalHandler.closeModal(id)
    if (onClose) {
      onClose()
    }
  }
}

export const ModalClose = ({
  id,
  onSubmit,
  onClose,
  textSave = 'Save',
  textClose = 'Close',
}) => (
  <div className="modal-header" style={style1}>
    <Button.Button5 onClick={onSubmit} style={{ marginRight: 10 }}>
      {textSave}
    </Button.Button5>
    <Button.Button2 onClick={handleClose(id, onClose)}>
      {textClose}
    </Button.Button2>
  </div>
)

const styleBoxLoad = { position: 'relative', height: '80px' }

function handle(props) {
  const [isLoading, setIsLoading] = useState(false)
  const [receivers, setReceivers] = useState([{ name: '', email: '' }])
  const [message, setMessage] = useState('')
  const [language, setLanguage] = useState('EN')

  const onChangeName = data => {
    return e => {
      data[0].name = e.currentTarget.value
      setReceivers(data)
    }
  }
  const onChangeEmail = data => {
    return e => {
      data[0].email = e.currentTarget.value
      setReceivers(data)
    }
  }
  const onChangeLanguage = e => setLanguage(e.currentTarget.value)
  const onMessage = e => setMessage(e.currentTarget.value)
  // const onAddReceivers = async () => {
  //   const _name = await swal({
  //     title: props.locale.LABEL_INPUT_NAME,
  //     content: {
  //       element: 'input',
  //       attributes: {
  //         placeholder: 'Name',
  //         type: 'text',
  //       },
  //     },
  //   })
  //   if (_name === '') {
  //     swal('Error!', props.locale.WARNING_NAME_EMPTY, 'error')
  //     return false
  //   }
  //
  //   const _email = await swal({
  //     title: props.locale.LABEL_INPUT_EMAIL,
  //     content: {
  //       element: 'input',
  //       attributes: {
  //         placeholder: 'Email',
  //         type: 'text',
  //       },
  //     },
  //   })
  //   if (_email === '') {
  //     swal('Error!', props.locale.WARNING_EMAIL_EMPTY, 'error')
  //     return false
  //   } else if (!validateEmail(_email)) {
  //     swal('Error!', props.locale.WARNING_EMAIL_FORMAT_INCORRECT, 'error')
  //     return false
  //   }
  //   setReceivers(x => {
  //     x.push({ 'name': _name, 'email': _email })
  //     return x
  //   })
  // }
  // const onRemoveReceiver = index => () => {
  //   swal({
  //     className: 'swal-popup-confirm',
  //     title: props.locale.WARNING,
  //     text: props.locale.WARNING_WANT_DELETE + '?',
  //     icon: 'warning',
  //     buttons: {
  //       cancel: {
  //         text: props.locale.CANCEL,
  //         value: null,
  //         visible: true,
  //         className: 'swal-popup-btn-cancle',
  //         closeModal: true,
  //       },
  //       confirm: {
  //         text: props.locale.OK,
  //         value: true,
  //         visible: true,
  //         className: 'swal-popup-btn-ok',
  //         closeModal: true,
  //       },
  //     },
  //
  //   }).then((willDelete) => {
  //     if (willDelete) {
  //       setReceivers(x => {
  //         x.splice(index, 1)
  //         return x
  //       })
  //     }
  //   })
  // }
  const onSubmit = () => {
    if (receivers.length <= 0) {
      swal(
        props.locale.ERROR,
        props.locale.WARNING_INVALID_INPUT + '!',
        'error'
      )
    } else {
      setIsLoading(true)
      props.onSubmit(
        {
          receivers: [...receivers],
          message,
          language,
        },
        res => {
          const { code, text } = res
          if (code === 'FAIL') {
            swal(props.locale.ERROR, (text && text.error) || '', 'error')
          } else {
            ModalHandler.closeModal('modalInviteNewMyClub')
          }
          setIsLoading(false)
          onReset()
        }
      )
    }
  }
  const onReset = () => {
    setReceivers([{ name: '', email: '' }])
    setMessage('')
    setLanguage('EN')
  }

  return {
    isLoading,
    receivers,
    message,
    language,
    onChangeName,
    onChangeEmail,
    onChangeLanguage,
    onMessage,
    onSubmit,
    onReset,
  }
}

function InviteNewMyClub(props) {
  const {
    isLoading,
    receivers,
    message,
    language,
    onChangeName,
    onChangeEmail,
    onChangeLanguage,
    onMessage,
    onSubmit,
    onReset,
  } = handle(props)

  return (
    <Modal id="modalInviteNewMyClub">
      <ModalContent maxWidth={400}>
        <ModalHeader
          id="modalInviteNewMyClub"
          title={props.locale.NEW_INVITATION}
          onClose={onReset}
        />
        <Body>
          {isLoading && (
            <div style={styleBoxLoad}>
              <Loading />
            </div>
          )}
          <Table>
            <tbody>
              <tr>
                <TdInput>
                  {props.locale.RECEIVER_NAME}:
                  <input
                    type="text"
                    value={receivers[0].name}
                    onChange={onChangeName(receivers)}
                  />
                </TdInput>
              </tr>
              <tr>
                <TdInput>
                  {props.locale.RECEIVER_EMAIL}:
                  <input
                    type="text"
                    value={receivers[0].email}
                    onChange={onChangeEmail(receivers)}
                  />
                </TdInput>
              </tr>
            </tbody>
          </Table>
        </Body>
        <ModalClose
          id="modalInviteNewMyClub"
          onSubmit={onSubmit}
          onClose={onReset}
          textSave={props.locale.SEND}
          textClose={props.locale.CLOSE}
        />
      </ModalContent>
    </Modal>
  )
}

export default InviteNewMyClub

InviteNewMyClub.defaultProps = {
  onSubmit: null,
}
