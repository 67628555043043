import React, { useState } from 'react'
import Modal from 'react-modal'
import Button from '../../form/buttons'
import IconClose from '../../../images/ic_close.svg'
import IconWarning from '../../../images/icons/ic_warning.png'
const DeleteMember = props => {
  const { state, clearDelete, handleDeleteUserInfo, locale } = props
  const { visible, profileId } = state
  const handleSubmit = async () => {
    await handleDeleteUserInfo(profileId)
  }
  return (
    <Modal
      isOpen={visible}
      onRequestClose={clearDelete}
      className="information__modal"
      overlayClassName="information__modal__overlay"
    >
      <img
        src={IconClose}
        alt=""
        className="information__modal__close"
        onClick={clearDelete}
      />
      <div className="content__modal">
        <div className="title__modal" style={{ marginTop: '40px' }}>
          <img src={IconWarning} alt="warning" />
        </div>
        <div className="box__input__modal">
          Are you sure to delete this member?
        </div>
      </div>
      <div className="flex">
        <button className="popup-cancel-delete" onClick={clearDelete}>
          {locale['NO']}
        </button>
        <button className="popup-confirm-delete" onClick={handleSubmit}>
          {locale['YES']}
        </button>
      </div>
    </Modal>
  )
}
export default DeleteMember
