import React, { useState, useEffect } from 'react'
import Modal from 'react-modal'
import Button from '../../form/buttons'
import IconClose from '../../../images/ic_close.svg'
const AddMember = props => {
  const {
    isOpen,
    locale,
    handleAddMember,
    handleModalAddMember,
    firstname = '',
    lastname = '',
    student_id = '',
  } = props
  const [useMember, setMember] = useState({
    firstname: firstname,
    lastname: lastname,
    student_id: student_id,
  })
  const handleOnChange = (e, key) => {
    const isNumber = /^[0-9\b]+$/
    if (key === 'student_id') {
      if (isNumber.test(e.target.value) || e.target.value === '') {
        setMember({ ...useMember, [key]: e.target.value })
      }
    } else {
      setMember({ ...useMember, [key]: e.target.value })
    }
  }
  const handleSubmit = async () => {
    await handleAddMember(useMember)
    setMember({ firstname: '', lastname: '', student_id: '' })
  }
  useEffect(
    () => {
      setMember({ firstname, lastname, student_id })
    },
    [firstname, lastname, student_id]
  )
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleModalAddMember}
      className="information__modal"
      overlayClassName="information__modal__overlay"
    >
      <img
        src={IconClose}
        alt=""
        className="information__modal__close"
        onClick={handleModalAddMember}
      />
      <div className="content__modal">
        <div className="title__modal">{locale['ADD_MEMBER']}</div>
        <div className="box__input__modal">
          <div>
            <p className="information__label">ชื่อ</p>
            <input
              name="firstname"
              type="text"
              value={useMember.firstname}
              onChange={e => handleOnChange(e, 'firstname')}
              placeholder="กรอกชื่อ"
              className="information__input"
            />
          </div>
          <div>
            <p className="information__label">นามสกุล</p>
            <input
              name="lastname"
              type="text"
              value={useMember.lastname}
              onChange={e => handleOnChange(e, 'lastname')}
              placeholder="กรอกนามสกุล"
              className="information__input"
            />
          </div>
          <div>
            <p className="information__label">รหัสนักศึกษา</p>
            <input
              name="student_id"
              type="tel"
              value={useMember.student_id}
              onChange={e => handleOnChange(e, 'student_id')}
              placeholder="กรอกรหัสนักศึกษา"
              className="information__input"
            />
          </div>
        </div>
      </div>
      <Button.Button7 style={{ padding: 0, width: 164 }} onClick={handleSubmit}>
        {locale['ADD_MEMBER']}
      </Button.Button7>
    </Modal>
  )
}
export default AddMember
