import React, { useState } from 'react'
import Modal from 'react-modal'
import Loading from '../../loading/loading-relative'
import Button from '../../form/buttons'
import IconClose from '../../../images/ic_close.svg'
import IconWarning from '../../../images/icons/ic_warning.png'

const ManageRequest = props => {
    const { isOpen, locale, handleManageRequest, handleModalManageRequest, ManageRequestAction } = props
    const [isLoading, setIsLoading] = useState(false)
    const setLoadingOn = () => setIsLoading(true)
    const setLoadingOff = () => setIsLoading(false)
    const styleBoxLoad = { position: 'relative', height: '80px', marginTop: '-40px' }
    async function onApproveAction (callback) {
        await handleManageRequest()
        callback()
        }
    return (
        <Modal
            isOpen={isOpen}
            className="information__modal"
            overlayClassName="information__modal__overlay"
        >
            <img
                src={IconClose}
                alt=""
                className="information__modal__close"
                onClick={handleModalManageRequest}
            />
            <div className="content__modal">
                <div className="title__modal" style={{ marginTop: '40px' }}>
                    <img src={IconWarning} alt="warning" />
                </div>
                <div className="box__input__modal">
                    {ManageRequestAction === 'approve' ? locale['WARNING_MEMBER_APPROVE'] : locale['WARNING_MEMBER_REJECT']}
                </div>
            </div>
            <div className="flex">
                {isLoading === false ? (
                    <div>
                        <button className="popup-cancel-delete" onClick={handleModalManageRequest}>
                            {locale['NO']}
                        </button>
                        <button className="popup-confirm-delete" onClick={() => {
                            setLoadingOn()
                            onApproveAction(setLoadingOff)
                        }}>
                            {locale['YES']}
                        </button>
                    </div>) : (
                        <div style={styleBoxLoad}>
                            <Loading />
                        </div>)
                }
            </div>
        </Modal>
    )

}

export default ManageRequest