import React, { Fragment } from 'react'
import styled from 'styled-components'
import media from 'styled-media-query'
import MediaQuery from 'react-responsive'
import ClubLayout from './layout'
import {
  searchMemberInClub,
  createUserProfileInfo,
  listMemberInClub,
  listDataPersonalInfo,
  getProfileFromId,
  getPositionFromId,
  postJoinClub,
  postUpdateProfileInfo,
  deleteUserInfo,
  postInviteClub,
  requestToClub,
  requestToClubAction,
} from '../../utils/api'
import ReactPaginate from 'react-paginate'
import Loading from '../loading'
import _ from 'lodash'
import { navigate } from 'gatsby'
import MemberDetail from './components/member-detail'
import ModalInviteNewMyClub from '../../components/popup/invite-new-my-club'
import ModalImportMyClub from '../../components/popup/import-my-club'
import AddMember from './components/add-member'
import ManageRequest from './components/manage-request'
import ModalHandler from '../../utils/modal'
import EditMember from './components/edit-member'
import DeleteMember from './components/delete-member'
import IconDown from '../../images/ic_down.svg'
import IconPlus from '../../images/club-event/plus.png'
import IconTrash from '../../images/icons/ic_trash.png'
import IconEdit from '../../images/icons/ic_edit.png'
import IconOther from '../../images/icons/ic_other.png'
import Cookies from 'universal-cookie'
import PopUpInformationModal from '../club/components/popupInformation-modal'
const cookies = new Cookies()

const BoxInputAndAddMember = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 24px;
  margin-bottom: 48px;
  .box-left {
    flex: 1;
  }
  .box-right {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
`
const ButtonAddMember = styled.button`
  position: relative;
  font-family: RSU;
  font-size: 16px;
  color: #ffffff;
  letter-spacing: 0.5px;
  text-align: center;
  line-height: 28px;
  padding: 10px 41px;
  background: #892a24;
  border-radius: 8px;
`
const SelectBox = styled.select`
  font-size: 20px;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  padding: 2px 26px 3px 10px;
  border-radius: 4px 0 0 4px !important;
  line-height: 35px;
  background: #fff url(${IconDown}) no-repeat right;
  box-shadow: 0 2px 25px 0 rgba(0, 0, 0, 0.08);
  -webkit-appearance: none;
  background-position-x: 90%;
`

const InputSearchContainer = styled.div`
  position: relative;
  width: 250px;
  font-size: 16px;

  & i {
    font-size: 12px;
    position: absolute;
    top: 13px;
    left: 13px;
  }
  ${media.lessThan('medium')`
    width: 100%;
  `}
`

const InputSearch = styled.input`
  background: #ffffff;
  /* border-left: 1px solid #f0f0f1; */
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.08);
  border-radius: 0 4px 4px 0 !important;
  height: 40px;
  width: 100%;
  padding-left: 32px;
  padding-right: 16px;
`

const ColumnHeader = styled.div`
  opacity: 0.87;
  font-family: RSU-Bold;
  font-size: 24px;
  color: #3f3131;
  flex: 1;
`
const ColumnHeaderAction = styled.div`
  opacity: 0.87;
  font-family: RSU-Bold;
  font-size: 24px;
  color: #3f3131;
  flex: 1;
  text-align: center;
`
const Line = styled.hr`
  border: 0;
  height: 1px;
  background: #f0f0f1;
  margin: 20px 0;
`

const ColumnValue = styled(ColumnHeader)`
  font-family: RSU;
  z-index: 1;
`
const ColumnAction = styled(ColumnHeader)`
  font-family: RSU;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  div {
    margin-right: 36px;
  }
  .assign,
  .edit,
  .delete {
    opacity: 0.87;
    font-family: RSU;
    font-size: 16px;
    color: #3f3131;
    border-bottom: 1px solid #3f3131;
  }
  .delete {
    color: #892a24;
  }
  .box-menu-mobile {
    margin-right: 0;
    position: relative;
    img {
      z-index: 1;
    }
    .sub-menu {
      z-index: 2;
      position: absolute;
      left: -170px;
      top: 0;
      margin-right: 16px;
      display: flex;
      flex-direction: column;
      background: #ffffff;
      box-shadow: 0 15px 25px 0 rgba(0, 0, 0, 0.08);
      border-radius: 0 0 4px 4px;
      span {
        white-space: nowrap;
        font-family: RSU;
        font-size: 16px;
        color: #3f3131;
        letter-spacing: 0.5px;
        text-align: left;
        line-height: 28px;
        padding: 6px 24px;
      }
      .delete {
        color: #892a24;
        border-bottom: none;
      }
    }
  }
`
const ColumnSubValue = styled.div`
  opacity: 0.34;
  font-size: 16px;
  color: #3f3131;
  letter-spacing: 0.5px;
  text-align: left;
  line-height: 28px;
`

/* eslint-disable jsx-a11y/anchor-is-valid */
export default class Club extends React.Component {
  static defaultProps = {
    hideBG: true,
    underlineColor: '#C14C40',
  }

  constructor(props) {
    super(props)
    this.state = {
      style: null,
      memberList: [
        // {
        //   username: 'eeace068-9200-45c6-81c2-0d90533df71a',
        //   club_id: '5de91157b197f8af6e15e80c',
        //   created_at: '2019-12-07T19:48:46.823Z',
        //   firstname: 'Phongpat',
        //   lastname: 'Namjaingom',
        //   class_of: 'Class of 38',
        //   student_id: '123456',
        //   position: 'Owner',
        //   is_active: true,
        // },
      ],
      requestToClubList: [],
      selectRequester: {
        //  name: 'first+lastname',
        //  email: 'email'
        //  lang: 'lang'
      },
      informationOnly: false,
      additionalInfoToModal: '',
      openInformationModal: false,
      memberApproveResult: 'string',
      informationType: '',
      search: '',
      offset: 0,
      total: 0,
      loading: false,
      list: null,
      selectedMember: null,
      selectFilter: 'all',
      openModal: false,
      openModalAddMember: false,
      openModalManageRequest: false,
      ManageRequestAction: '',
      openModalEditMember: {
        visible: false,
        profileId: '',
        firstname: '',
        lastname: '',
        student_id: '',
        user_info: {},
      },
      openModalDeleteMember: {
        visible: false,
        profileId: '',
      },
      isPermission: '',
      visibleActionMobile: { visible: false, profileId: '', email: '' },
    }
    this.debounceDelay = _.debounce(this.debounceDelay.bind(this), 1000)
    this.debounceDelay2 = _.debounce(this.debounceDelay2.bind(this), 300)
    this.handleModalManageRequest = this.handleModalManageRequest.bind(this)
    this.handleManageRequestAction = this.handleManageRequestAction.bind(this)
    this.handleManageRequest = this.handleManageRequest.bind(this)
    this.handlepopUpInformation = this.handlepopUpInformation.bind(this)
    this.handleAdditionalInformation = this.handleAdditionalInformation.bind(this)
  }

  async componentDidMount() {
    let uid = null
    if (this.props.authState && this.props.authState.currentUser) {
      uid = this.props.authState.currentUser.username
    } else if (this.props.cookieAuth) {
      uid = this.props.cookieAuth
    }
    if (uid) {
      const userData = await getProfileFromId(uid)
      if (!userData.data) {
        navigate('/club/KMITLEngineerAlumni')
      }
    }
    if (uid) {
      const positionData = await getPositionFromId(uid)
      if (positionData && positionData.data) {
        this.setState({ isPermission: positionData.data.position || '' })
      }
    }
    const w = document.documentElement.clientWidth
    const bgColor = this.props.hideBG ? null : { backgroundColor: '#E8E8E8' }
    if (w <= 450) {
      this.setState({ style: { padding: '20px 10px', ...bgColor } })
    } else {
      this.setState({ style: { padding: '20px 0', ...bgColor } })
    }
    const list = await listDataPersonalInfo()
    this.setState({ list: list.data })
    if (this.props.auth) {
      const data = await listMemberInClub()
      if (data.data && data.data.members) {
        this.setState({ memberList: data.data.members, total: data.data.total })
      } else {
        this.setState({ memberList: [...this.state.memberList] })
      }
    }
    this.getRequstToClibList()
  }

  debounceDelay = value => {
    this.searchNoAuth(value)
  }

  debounceDelay2 = value => {
    this.getListMemberInClub()
  }

  getListMemberInClub = async () => {
    const data = await listMemberInClub()
    if (data.data) {
      this.setState({
        memberList: data.data.members,
        total: data.data.total,
        loading: false,
      })
    }
  }
  // Try to get list of users that request to be a member
  getRequstToClibList = async () => {
    const data = await requestToClub(this.props.clubRegister.user_of)
    if (data.data) {
      this.setState({
        requestToClubList: data.data,
        loading: false,
      })
    }
  }

  searchNoAuth = async (search = '', skip = 0) => {
    const data = await searchMemberInClub(search, skip, this.state.selectFilter)
    console.log(data.data)
    if (this.state.search) {
      this.setState({
        memberList: data.data.members || [],
        total: data.data.total,
        loading: false,
      })
    } else {
      if (this.props.auth) {
        this.setState({
          memberList: [...this.state.memberList],
          loading: false,
        })
      } else {
        if (this.props.auth) {
          this.setState({
            memberList: [...this.state.memberList],
            loading: false,
          })
        } else {
          this.setState({ memberList: [], loading: false })
        }
      }
    }
  }

  styleFont1 = { fontFamily: `'Noto Serif', serif` }

  handleTabs = tab => {
    this.setState({ tab })
  }
  handleAddMember = async payload => {
    const create = await createUserProfileInfo({
      ...payload,
      user_of: this.props.clubRegister.user_of,
    })
    if (create.code === 'OK') {
      const update_member = await postJoinClub(
        {
          position: 'Member',
        },
        { 'X-Profile-ID': create.data._id }
      )
    }
    await this.handleModalAddMember()
    return
  }

  // Send invite to data to backend
  handleManageRequest = async () => {
    const updateUserStatusPayload = {
      'firstname': this.state.selectRequester.firstname,
      'lastname': this.state.selectRequester.lastname,
      'email': this.state.selectRequester.email,
      'status': this.state.ManageRequestAction,
      'action_by': this.props.authState.currentUser.attributes.email
    }
    if (this.state.ManageRequestAction === 'approve') {
      const payload = [this.state.selectRequester]
      const sendInvite = await postInviteClub(JSON.stringify(payload), {
        'X-Profile-ID': cookies.get('_id'),
        'Content-Type': 'application/json',
      })
      if (sendInvite.code === 'OK') {
        const updateUserApprove = await requestToClubAction(this.props.clubRegister.user_of, JSON.stringify(updateUserStatusPayload))
        if (updateUserApprove.code !== 'OK') {
          alert(updateUserApprove.text.error)
        }
        this.getRequstToClibList()
        this.handleModalManageRequest()
      } else {
        this.setState({ memberApproveResult: sendInvite.text.error.slice(1, -1), informationType: 'negative' }, () => {
          this.handlepopUpInformation()
          this.handleModalManageRequest()
        })
      }
    } else {
      const updateUserReject = await requestToClubAction(this.props.clubRegister.user_of, JSON.stringify(updateUserStatusPayload))
      if (updateUserReject.code !== 'OK') {
        alert(updateUserReject.text.error)
      }
      this.getRequstToClibList()
      this.handleModalManageRequest()
    }
  }
  handleOnChange = (e, key) => {
    console.log(key, e.target.value)
    this.setState({ [key]: e.target.value })
  }
  handleModalAddMember = () => {
    this.setState({ openModalAddMember: !this.state.openModalAddMember })
  }
  handleModalManageRequest = () => {
    this.setState({ openModalManageRequest: !this.state.openModalManageRequest, informationOnly: false })
  }
  handleManageRequestAction = (ManageRequestAction) => {
    this.setState({ ManageRequestAction: ManageRequestAction })
  }
  handleChangeSelect = e => {
    this.setState({ selectFilter: e.target.value })
  }
  handlePermissionUser = async (type, id) => {
    const dataJoinClub = await postJoinClub(
      {
        position: type === 'add' ? 'Admin' : 'Member',
        is_active: true,
      },
      { 'X-Profile-ID': id }
    )
    if (dataJoinClub) {
      this.setState({
        memberList: this.state.memberList.map(item => {
          if (item.profile_id === id) {
            item.position = type === 'add' ? 'Admin' : 'Member'
          }
          return item
        }),
        visibleActionMobile: { visible: false, profileId: '' },
      })
    }
  }
  handleEditUserInfo = async (payload, id) => {
    const updateUserInfo = await postUpdateProfileInfo(payload, {
      'X-Profile-ID': id,
    })
    if (updateUserInfo) {
      this.setState({
        memberList: this.state.memberList.map(item => {
          if (item.profile_id === id) {
            item.profile.user_info.firstname = payload.user_info.firstname
            item.profile.user_info.lastname = payload.user_info.lastname
            item.profile.user_info.student_id = payload.user_info.student_id
          }
          return item
        }),
      })
      this.closeModalEditMember()
    }
  }
  closeModalEditMember = () => {
    this.setState({
      openModalEditMember: {
        visible: false,
        profileId: '',
        firstname: '',
        lastname: '',
        student_id: '',
        user_info: {},
      },
    })
  }
  addSetOpenEdit = ({
    firstname,
    lastname,
    student_id,
    profileId,
    user_info,
  }) => {
    this.setState({
      openModalEditMember: {
        visible: true,
        firstname,
        lastname,
        student_id,
        profileId,
        user_info: user_info,
      },
      visibleActionMobile: { visible: false, profileId: '' },
    })
  }
  addSetOpenDelete = profileId => {
    this.setState({
      openModalDeleteMember: { visible: true, profileId },
      visibleActionMobile: { visible: false, profileId: '' },
    })
  }
  clearDelete = () => {
    this.setState({ openModalDeleteMember: { visible: false, profileId: '' } })
  }
  handleDeleteUserInfo = async profileId => {
    const deleteUser = await deleteUserInfo({
      'X-Profile-ID': profileId,
    })
    if (deleteUser) {
      this.setState({
        memberList: this.state.memberList.filter(
          item => item.profile_id !== profileId
        ),
      })
      this.clearDelete()
    }
  }
  handleOpenPopup = id => {
    ModalHandler.openModal(id)
  }
  handlepopUpInformation = () => {
    this.setState({ openInformationModal: !this.state.openInformationModal })
  }
  onSubmitImportInvitation = async (payload, callback) => {
    let newMapData = []
    for (let i = 0; i < payload.file.length; i++) {
      const item = payload.file[i]
      const findDataSame = newMapData.find(
        f => f.name === item[0] && f.email === item[1]
      )
      if (!findDataSame) {
        newMapData.push({
          name: item[0] || '',
          email: item[1] || '',
          langauge: 'TH',
        })
      }
    }
    const importInvite = await postInviteClub(JSON.stringify(newMapData), {
      'X-Profile-ID': cookies.get('_id'),
      'Content-Type': 'application/json',
    })
    callback(importInvite)
  }
  onSubmitNewMyClub = async (payload, callback) => {
    const { receivers } = payload
    if (!receivers && receivers.length === 0) {
      callback({ code: 'FAIL', text: { error: 'field ไม่ครบ' } })
      return
    }

    let newMapData = [
      { name: receivers[0].name, email: receivers[0].email, language: 'TH' },
    ]
    const importInvite = await postInviteClub(JSON.stringify(newMapData), {
      'X-Profile-ID': cookies.get('_id'),
      'Content-Type': 'application/json',
    })
    callback(importInvite)
  }

  // handle popup additional information

  handleAdditionalInformation = (index) => {
    this.setState({informationOnly: true, additionalInfoToModal: this.state.requestToClubList[index].additional_info})
    this.handlepopUpInformation()
  }
  // handle approve request to club



  handleRenderAction = (firstname, lastname, email, locale) => {
    return (
      <>
        <MediaQuery minWidth={769}>
          <div style={{ textAlign: 'center', cursor: 'pointer', marginRight: 'auto', marginLeft: 'auto' }}
            onClick={() => {
              this.handleModalManageRequest()
              this.handleManageRequestAction('approve')
              this.setState({ selectRequester: { name: `${firstname} ${lastname}`, email: email, lang: this.props.lang, firstname: firstname, lastname: lastname } })
            }}
          >
            <div style={{ marginRight: '0px' }} alt="approve">
              {locale['APPROVE']}
            </div>
          </div>
          <div style={{ textAlign: 'center', cursor: 'pointer', marginRight: 'auto', marginLeft: 'auto' }}
            onClick={() => {
              this.handleModalManageRequest()
              this.handleManageRequestAction('reject')
              this.setState({ selectRequester: { name: `${firstname} ${lastname}`, email: email, lang: this.props.lang, firstname: firstname, lastname: lastname } })
            }}>
            <div style={{ marginRight: '0px' }} alt="reject">
              {locale['REJECT']}
            </div>
          </div>
        </MediaQuery>
        <MediaQuery maxWidth={768}>
          <div className="box-menu-mobile">
            <img
              src={IconOther}
              alt="other"
              onClick={() =>
                this.setState({
                  visibleActionMobile: { visible: true, email: email },
                })
              }
            />
            {(this.state.visibleActionMobile.visible && this.state.visibleActionMobile.email === email &&
              (<div className="sub-menu">
                <span
                  onClick={() => {
                    this.handleModalManageRequest()
                    this.handleManageRequestAction('approve')
                    this.setState({
                      visibleActionMobile: { visible: false },
                      selectRequester: { name: `${firstname} ${lastname}`, email: email, lang: this.props.lang, firstname: firstname, lastname: lastname },
                    })
                  }}
                >
                  APPROVE
                  </span>
                <span
                  onClick={() => {
                    this.handleModalManageRequest()
                    this.handleManageRequestAction('reject')
                    this.setState({
                      visibleActionMobile: { visible: false },
                      selectRequester: { name: `${firstname} ${lastname}`, email: email, lang: this.props.lang, firstname: firstname, lastname: lastname }
                    })
                  }}>
                  REJECT
                  </span>
              </div>
              ))}
          </div>
        </MediaQuery>
      </>
    )
  }
  display(props) {
    const { memberList, total } = this.state
    const { auth } = this.props
    return (
      <ClubLayout {...props}>
        <div>
          {/* {!auth && (
            <p>
              If you would like to see the list of others in your club, Please{' '}
              <span
                className="information__link"
                onClick={() => navigate('/club/KMITLEngineerAlumni/')}
              >
                {' '}
                Sign in
              </span>{' '}
              to be an Invitree account.
            </p>
          )} */}
          {/* if login and have member */}
          <Fragment>
            <div>
              <BoxInputAndAddMember>
                <div className="box-left">
                  <MediaQuery minWidth={769}>
                    {((this.state.isPermission == 'Admin' ||
                      this.state.isPermission == 'Owner') && (
                        <>
                          <ButtonAddMember
                            onClick={() =>
                              this.handleOpenPopup('modalInviteNewMyClub')
                            }
                            style={{ marginRight: '16px' }}
                          >
                            {props.locale['SEND_INVITE']}
                          </ButtonAddMember>
                          <ButtonAddMember
                            onClick={() =>
                              this.handleOpenPopup('modalImportMyClub')
                            }
                          >
                            {props.locale['SEND_INVITE_ALL']}
                          </ButtonAddMember>
                        </>
                      )) ||
                      null}
                  </MediaQuery>
                  <MediaQuery maxWidth={768}>
                    {((this.state.isPermission == 'Admin' ||
                      this.state.isPermission == 'Owner') && (
                        <>
                          <div
                            className="flex align-center btn-add"
                            onClick={() =>
                              this.handleOpenPopup('modalInviteNewMyClub')
                            }
                          >
                            {props.locale['SEND_INVITE']}
                          </div>
                          <div
                            className="flex align-center btn-add"
                            onClick={() =>
                              this.handleOpenPopup('modalImportMyClub')
                            }
                          >
                            {props.locale['SEND_INVITE_ALL']}
                          </div>
                        </>
                      )) ||
                      null}
                  </MediaQuery>
                </div>
              </BoxInputAndAddMember>
              <Line />
              <div>
                <div className="flex align-center">
                  <ColumnHeader>{props.locale['REQUESTER_NAME']}</ColumnHeader>
                  <MediaQuery minWidth={768}>
                    <ColumnHeader>{props.locale['EMAIL_ADDRESS']}</ColumnHeader>
                  </MediaQuery>
                  <MediaQuery minWidth={768}>
                    <ColumnHeaderAction>
                      {props.locale['TABLE_MENU_ACTION']}
                    </ColumnHeaderAction>
                  </MediaQuery>
                </div>
                <Line />
                {/* Data position */}
                {this.state.requestToClubList.map(({ firstname, lastname, email }, index, arr) => {
                  return (
                    <div key={index}>
                      <div className='flex'>
                        <ColumnValue>
                          <div style={{cursor: 'pointer'}} onClick={()=> this.handleAdditionalInformation(index)}>
                            {firstname} {lastname}
                          </div>
                        </ColumnValue>
                        <MediaQuery minWidth={768}>
                          <ColumnValue>
                            {email}
                          </ColumnValue>
                        </MediaQuery>
                        <ColumnAction>
                          {this.handleRenderAction(firstname, lastname, email, this.props.locale)}
                        </ColumnAction>
                      </div>
                      {arr.length - 1 !== index && <Line />}
                    </div>
                  )
                }
                )}
              </div>
            </div>
          </Fragment>
          {this.state.loading && <Loading />}
        </div>
        <ModalInviteNewMyClub
          locale={this.props.locale}
          onSubmit={this.onSubmitNewMyClub}
        />
        <ModalImportMyClub
          onSubmit={this.onSubmitImportInvitation}
          lang={this.props.lang.toLowerCase()}
          locale={this.props.locale}
        />
        <AddMember
          isOpen={this.state.openModalAddMember}
          locale={this.props.locale}
          handleAddMember={this.handleAddMember}
          handleModalAddMember={this.handleModalAddMember}
        />
        <ManageRequest
          isOpen={this.state.openModalManageRequest}
          locale={this.props.locale}
          handleManageRequest={this.handleManageRequest}
          handleModalManageRequest={this.handleModalManageRequest}
          ManageRequestAction={this.state.ManageRequestAction}
        />
        <PopUpInformationModal
          isOpen={this.state.openInformationModal}
          handlepopUpInformation={this.handlepopUpInformation}
          locale={this.props.locale}
          resultToDisplay={this.state.memberApproveResult}
          informationType={this.state.informationType}
          informationOnly={this.state.informationOnly}
          information={this.state.additionalInfoToModal}
        />
      </ClubLayout>
    )
  }

  render() {
    return this.display(this.props)
  }
}
